.navbar .dropdown-menu > li > a{
	@media (min-width: @screen-sm-min) {
		&:before {
			.fa-icon();
			content: @fa-var-circle;
			font-size: 6px;
			padding-right: 5px;
			line-height: 17px;
			display: inline-block;
			top: -2px;
			position: relative;
		}
	}
}

.navbar-inverse .navbar-toggle{
	background-color: darken(@brand-primary, 5%);
}