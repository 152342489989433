body.home {
	.row.news-btns {
		padding: 0 0 50px;
	}
	.intro {
		h1 {
			font-size: @font-size-h2;
		}
	}

	.home-top {
		.row {
			background: transparent;
			padding: 0 0 1em;
		}

		.col-big,
		.col-small {
			@media (max-width: @screen-sm-max) {
				padding-right: 7.5px;
				padding-left: 7.5px;

				&.first-child {
					padding-left: 15px;
				}
				&.last-child {
					padding-right: 15px;
				}
			}
		}

		.col-small {
			margin-top: 19.5px;
			@media (max-width: @screen-xs-max) {
				padding-right: 15px;
				padding-left: 15px;
			}

			@media (max-width: @screen-md-max) {
				margin-top: 26px;
			}

			@media (min-width: @screen-md) {
				&.first-child {
					margin-top: 0;
				}
			}
		}

		.block {
			border-radius: 0.3em;
			overflow: hidden;
			position: relative;
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
			img {
				width: 100%;
				transform: scale(1);
				transition: transform 0.25s;
			}
			&:hover img {
				transform: scale(1.2);
			}
			.caption {
				background-color: @red;
				color: #fff;
				padding: 0.5rem 1rem;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
			}

			&.big {
				img {
					height: 150px;
					@media (min-width: @screen-sm) {
						height: 270px;
					}
					@media (min-width: @screen-lg) {
						height: 310px;
					}
				}
				.caption {
					font-size: 1.4rem;
					@media (min-width: @screen-md) {
						font-size: 2rem;
					}
				}
			}
			&.small {
				border-left: 1em @red solid;
				img {
					height: 85px;
					@media (min-width: @screen-sm) {
						height: 90px;
					}
				}
				.caption {
					background-color: transparent;
					text-align: right;
					font-size: 1.6rem;
					text-shadow: 1px 1px 2px #000;
				}
			}
		}
	}

	.container {
		z-index: 100;
	}

	@media (min-width: 768px) {
		.home-top {
			position: relative;
			margin-top: -60px;
		}

		.home-top .container {
			position: relative;
		}
	}
}

.divider {
	.line {
		background-color: #d8d8d8;
		height: 0.4rem;
		width: 100px;
		margin: 2rem auto;
		border-radius: 4px;
		@media (min-width: @screen-sm) {
			width: 200px;
			margin: 4rem auto;
		}
		@media (min-width: @screen-md) {
			margin: 5.6rem auto;
		}
	}
}
