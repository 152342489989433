.links {
	display: none;
	padding: 5px 0 7px;
	background-color: @yellow;
	.list-inline {
		img, span {
			display: inline-block;
			vertical-align: top;
			line-height: 36px;
			height: 36px;
		}
	}
}

body footer {
	section.social .fa {
		background: #c20b1a;
		height: 1.5em;
		width: 1.5em;
		text-align: center;
		line-height: 1.5em;
		border-radius: 2em;
		font-size: 24px;
		&:hover {
			color: #c20b1a;
			background: white;
		}
	}
	section.contact{
		.fa{
			margin-right: 1rem;
			display: inline-block;
		}
	}
}