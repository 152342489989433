.actionpage.category {
  padding: 1em;
  border: 1px solid #eeeeee;
  overflow: hidden;
  background: #fdfdfd;
  margin: 0 0 1em;
}

.actionpage.category figure {
  border: 1px solid #eeeeee;
  border-bottom: 0;
}

.actionpage.category figure img {
  width: 100%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
}

.actionpage.category h2 {
  margin: 0 0 0.3em;
}

.actionpage.category p {
  margin: 1em 0 0;
  color: #666666;
  font-size: 1.1em;
}

.actionpage.category .category-products {
  background: transparent;
  padding-bottom: 0;
  margin-bottom: 0;
}

.actionpage.category .category-products li {
  position: relative;
  list-style: none;
  margin: 1.5em 0 0;
}

.actionpage.category .category-products li h3 {
  font-size: 1.1em;
  margin: 0.5em 0;
  color: white;
}

.actionpage.category .category-products li .onsale {
  position: absolute;
  top: 0;
  right: 0;
  background: #ffee09;
  color: #333333;
  text-shadow: 0 -1px 0 #d6c913;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3), inset 0 -1px 0 rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3), inset 0 -1px 0 rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3), inset 0 -1px 0 rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 20px;
  border-radius: 20px;
  padding: 0.5em;
  font-size: 1.1em;
  transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  -webkit-transform: rotate(30deg);
}

.actionpage.category .category-products li .price {
  font-size: 1.2em;
}

.actionpage.category .category-products li .price del {
  font-size: 0.67em;
  color: rgba(255, 255, 255, 0.5);
  display: block;
}

.actionpage.category .category-products li .price ins {
  text-decoration: none;
}

.actionpage.category .category-products li .meta {
  background: #e01020;
  color: white;
  padding: 0.5em;
  text-align: center;
}

.actionpage.category .category-products li.first {
  clear: both;
}

#menu-primary-menu a[title="actie"] {
  background: #efd400;
  color: #333333;
}
