.wpcf7 {
	.screen-reader-response {
		display: none;
	}
	.wpcf7-not-valid-tip {
		padding: 10px;
		display: block;
		background-color: @alert-danger-bg;
		color: darken(@alert-danger-bg, 30%)
	}
	.input-group {
		.wpcf7-not-valid-tip {
			border: 1px solid #cccccc;
			border-radius: 0 0px 4px 0px;
			border-top: none;
			float: left;
			width: 100%;
			padding: 5px 10px;
		}
		.form-control {
			&.wpcf7-not-valid {
				border-radius: 0 4px 0px 0;
			}
		}
	}
	.wpcf7-response-output {
		margin-top: 15px;
		&.wpcf7-validation-errors,
		&.wpcf7-spam-blocked {
			background-color: @alert-danger-bg;
			color: darken(@alert-danger-bg, 30%)
		}
	}

	// Recaptcha specific styles:
	.wpcf7-form-control-wrap {
		noscript + .wpcf7-not-valid-tip {
			border: none;
			border-radius: 0;
		}
	}

	// Ajax Loader
	.ajax-loader {
		.fa-icon();
		visibility: hidden;
		display: inline-block;
		width: 16px;
		height: 16px;
		border: none;
		padding: 0;
		margin: 0 0 0 4px;
		vertical-align: middle;
		-webkit-animation: fa-spin 2s infinite linear;
		animation: fa-spin 2s infinite linear;
		&:before{
			content: @fa-var-cog;
		}
		&.is-active {
			visibility: visible;
		}
	}
}