.site-title{
	z-index: 10000;
	box-shadow: none;
	background: transparent;
	padding: 5px 0;
	position: absolute;
	top: 0;
	margin-top: 0;
	@media (min-width: @screen-sm-min){
		padding: 4px;
		background: white;
		-webkit-box-shadow: 3px 3px 10px 0px rgba(50, 50, 50, 0.75);
		-moz-box-shadow: 3px 3px 10px 0px rgba(50, 50, 50, 0.75);
		box-shadow: 3px 3px 10px 0px rgba(50, 50, 50, 0.75);
		top: 49px;
	}
	> a{
		height: 45px;
		width: auto;
		padding: 0;
		display: block;
		background: transparent url("@{base-path}/logo.svg") no-repeat;
		text-indent: -999px;
		@media (min-width: @screen-sm-min){
			padding: 4px;
			width: 156px;
			height: 133px;
			background: white url("@{base-path}/logo.svg") no-repeat;
		}
	}
}