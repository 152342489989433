.btn {
  transition: all .3s ease-in-out;
  border: none;
  @media screen and (min-width: @screen-md-min){
    padding: 0.5em 2.5em;
  }
  .fa {
    font-size: 1.2em;
    line-height: 100%;
    margin: 0 5px;
  }
}

.btn-yellow {
  .button-variant(@btn-yellow-color; @btn-yellow-bg; @btn-yellow-border);
}

.btn {
  &.nav-previous,
  &.nav-next{
    color: #fff;
    a,
    a:hover,
    a:focus {
      color: #fff;
      text-decoration: none;
    }
  }
}