article.post {
	header {
		h1, h2 {
			display: inline-block;
			margin-top: 0;
		}
		.entry-meta {
			margin-top: 0;
			margin-bottom: 10px;
		}
	}
}

.pagination {
	display: inline-block;
	white-space: nowrap;
	padding: 0;
	border: 1px solid #d3ced2;
	border-right: 0;
	line-height: 0;
	margin: 30px 0 0;
}

.pagination .page-numbers {
	border-right: 1px solid #d3ced2;
	display: block;
	float: left;
	overflow: hidden;
	margin: 0;
	padding: 8px 14px;
	line-height: 1;
}

.pagination .page-numbers:hover {
	text-decoration: none;
	background: #ebe9eb;
	color: #8a7e88;
}

.pagination .current {
	background: #ebe9eb;
	color: #8a7e88;
}