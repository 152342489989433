@block-size: 130px;

section.dealers {
	text-align: center;
	background: url("@{base-path}/dealers-bg.png") repeat;
	overflow: hidden;
	header {
		h3 {
			color: #fff;
			line-height: 1;
			padding: 10px;
			margin: 0;
		}
		background-color: rgba(0, 0, 0, 0.5)
	}
	.dealer-container {
		display: inline-block;
		overflow: hidden;
	}
	ul.list-table {
		margin: 20px 0;
		padding: 0;
		animation: moveSlideshow 12s .5s linear infinite;
		-webkit-animation: moveSlideshow 12s .5s linear infinite;
		@media screen and (orientation: landscape) and (min-width: @screen-md-min){
			&:hover{
				-webkit-animation-play-state: paused;
				-moz-animation-play-state: paused;
				-o-animation-play-state: paused;
				animation-play-state: paused;
			}
		}

		li {
			vertical-align: middle;
			padding: 0 5px;
		}
	}
	img {
		width: 100px;
		max-height: 100px;
	}
	a {
		height: @block-size;
		vertical-align: middle;
		display: table-cell;
		background-color: rgba(0, 0, 0, 0.5);
		margin: 0 5px;
		padding: 10px;
		width: @block-size;
	}
}

@keyframes moveSlideshow {
	100% {
		transform: translateX(-50%);
	}
}