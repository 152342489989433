/*Theme Name:   Arie Molenaar Motors
 *Theme URI:    http://creativepassenger.com/
 *Description:  Arie Molenaar Motors
 *Author:       Creative Passenger
 *Author URI:   http://creativepassenger.com
 *Version:      1.0.0
 *Tags:         light, dark, two-columns, right-sidebar, responsive-layout, accessibility-ready
 *Text Domain:  mr */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

body, a, h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Abel", sans-serif;
  font-weight: normal;
}

.display-posts-listing .fa-enabled > .fa {
  background: #ededed;
  border-radius: 2em;
  text-align: center;
  line-height: 2em;
  display: block;
  font-size: 3.6em;
  height: 2em;
  width: 2em;
  float: left;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  -moz-transition: -moz-transform 0.2s ease-in-out;
  -o-transition: -o-transform 0.2s ease-in-out;
  -ms-transition: -ms-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  -webkit-backface-visibility: hidden;
}

/* Only applies to normal / no touch enabled devices */
.no-touch .display-posts-listing .fa-enabled > .fa:hover {
  background: #feec36;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

.text-justify {
  text-align: justify;
}


html.newsletter-fullpage {
  background: #2b3130;
  background-image: url("../images/bg-fullpage-newsletter.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  margin: 0;
  overflow: hidden;
  height: 100%;
}

html.newsletter-fullpage body {
  background: transparent;
}

html.newsletter-fullpage body .logo {
  left: 2em;
  top: 2em;
  position: fixed;
}

html.newsletter-fullpage body .box {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}

/* Calendar Widget */
.tribe-list-widget {
  padding: 0;
}

.tribe-events-list-widget h3 {
  margin: 0 0 0.7em;
}

.tribe-events-list-widget .tribe-events-widget-link a {
  font-weight: normal;
}

.tribe-events-list-widget ol.vcalendar {
  padding: 0;
}

.tribe-events-list-widget ol.vcalendar li {
  background: #fafafa;
  border: 1px solid #e5e5e5;
  border-left: 4px solid #b4b574;
  padding: 2%;
}

.tribe-events-list-widget ol.vcalendar li .duration {
  font-size: 14px;
}

.tribe-events-list-widget ol.vcalendar li h4 {
  margin: 0;
}

.tribe-events-list-widget ol.vcalendar li h4 a {
  color: #6b6b6b;
  font-size: 16px;
  margin: 0 0 5px 0;
  display: block;
  text-transform: uppercase;
}

.white {
  background: white;
}

body.woocommerce ul.products li.product .price, body.woocommerce #content div.product p.price {
  font-size: 1.2em;
}

.woocommerce ul.products li.product .price del,
.woocommerce-page ul.products li.product .price del {
  font-size: 0.8em;
  margin: 0 1em 0 0;
  color: red;
}

.woocommerce ul.products li.product .price ins,
.woocommerce-page ul.products li.product .price ins {
  text-decoration: none;
}

.yellow-bg {
  background: #EFD500;
}

.white-bg {
  background: #fff;
}

.staff h4 {
  font-size: 28px;
  color: @brand-primary;
}

.staff ul {
  margin: 0 -15px;
}

.staff ul li {
  padding: 0 15px;
  float: left;
  width: 25%;
}

.staff ul li img {
  max-width: 100%;
  height: auto;
}

/* Bootstrap overrides */
.navbar-collapse {
  padding-left: 0;
  padding-right: 0;
}

/* newsletter slide in widget */
.stb-container h4 {
  display: none;
}

.stb-container ul {
  padding: 0;
  margin: 0;
}

.stb-container ul, .stb-container h3, .stb-container p {
  text-align: center;
}

.stb-container .btn-primary {
  margin: 1em 0 0 0;
  width: 100%;
  font-size: 16px;
}

.stb-container p {
  font-size: 16px;
  margin: 1.5em 0;
}

.grey {
  background: #EDEDED none repeat scroll 0% 0%;
}

body {
  /* Landingspage Newsletter specific styles */
}



body.container {
  overflow: hidden;
}

body a {
  color: @brand-primary;
}

body a:hover {
  color: #c20b1a;
}

body h1, body h2, body h3 {
  color: #393939;
}

body h1 a, body h2 a, body h3 a {
  color: #393939;
}

body h1:hover, body h1 a:hover {
  color: #393939;
  text-decoration: none;
}

body h2:hover, body h2 a:hover {
  color: #393939;
  text-decoration: none;
}

body h3 {
  font-size: 2em;
}

body h3:hover {
  text-decoration: none;
}

body h3 a {
  color: @brand-primary;
  font-size: 2em;
}

body h3 a:hover {
  color: #393939;
  text-decoration: none;
}

body h3:hover {
  font-size: 2em;
}

body #occassions .pagination ul {
  margin: 0;
  padding: 0;
}

body #occassions select {
  display: inline-block;
  padding: 4px;
  color: #555555;
  border: 1px solid #ccc;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

body #occassions .control-group {
  border: 1px solid #eee;
  margin-bottom: 15px;
  border-radius: 0px 0px 5px 5px;
}

body #occassions .control-group .controls {
  padding: 10px;
}

body #occassions .control-group .controls .input-small {
  width: 48%;
}

body #occassions .controls label.checkbox {
  margin-left: 20px;
}

body #occassions .controls label {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: normal;
}

body #occassions .item {
  width: 100%;
}

body #occassions ul.thumbnails li.item:nth-child(3n+1) {
  clear: left;
}

body #occassions ul.thumbnails .unitBlock {
  padding: 0;
}

body #occassions h5 {
  margin-top: 0;
}

body .links ul {
  margin: 0;
  padding: 0;
  max-width: 100%;
}

body .links ul li {
  color: #000;
  font-size: 12px;
  margin: 0 10px;
}

body .links ul li .fa, body .links ul li a {
  color: #000;
  font-size: 12px;
}

body .links ul li img {
  margin: 0 7px 0 0;
  height: 36px;
}

body .links ul li .fa {
  margin: 0 0 0 7px;
}


body .sidebar .widget {
  margin: 0 0 3em;
}

body img.alignleft {
  float: left;
  margin: 2em 2em 2em 0;
}

body img.aligncenter {
  clear: both;
  display: block;
  margin: 0.85714rem auto;
}

body img.alignright {
  float: right;
  margin: 2em 0 2em 2em;
}

body .nav-single.nav-bottom {
  margin: 3em 0 0;
}

body.single .row {
  padding-top: 0;
}

body .entry-meta {
  font-size: 12px;
  color: #393939;
}

body .entry-meta a {
  font-size: 12px;
  color: #393939;
}

body .entry-meta > span {
  margin: 0 0.5em 0 0;
  padding: 0 0.5em 0 0;
}

body .entry-meta > span:last-child {
  border: none;
}

body .wpcf7-form .input-group {
  width: 100%;
  margin: 0 0 0.5em 0;
}

body .wpcf7-form .input-group .input-group-addon {
  padding: 0 1em;
  width: 3em;
}

body .wpcf7-form .input-group .form-control {
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  -ms-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}

body .wpcf7-form .form-group {
  padding: 0;
  margin-bottom: 5px;
}

body .wpcf7-form .screen-reader-response {
  display: none;
}

body .wpcf7-form .wpcf7-response-output {
  padding: 1em;
}

body .wpcf7-form .wpcf7-response-output.wpcf7-mail-sent-ok {
  background-color: #dff0d8;
}

body.woocommerce .product_title {
  width: 100%;
}

body.woocommerce span.label,
body.woocommerce span.onsale {
  display: none;
}

@media (min-width: 1200px) {
  body.woocommerce span.label,
  body.woocommerce span.onsale {
    display: inline-block;
  }
}

body.woocommerce span.label {
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  text-shadow: 0 -1px 0 #d6c913;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3), inset 0 -1px 0 rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3), inset 0 -1px 0 rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3), inset 0 -1px 0 rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 20px;
  border-radius: 10px;
  padding: 0.5em;
  font-size: 1.1em;
  font-weight: normal;
  padding: 10px 10px;
  line-height: 1;
}

body.woocommerce span.onsale {
  background: #ffee09;
  text-shadow: 0 -1px 0 #d6c913;
  position: absolute;
  top: 0;
  right: 0;
  color: #333333;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3), inset 0 -1px 0 rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3), inset 0 -1px 0 rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3), inset 0 -1px 0 rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 20px;
  border-radius: 10px;
  font-size: 1.1em;
  font-weight: normal;
  padding: 10px;
  margin: 0px -15px 0 0;
  line-height: 1;
  height: auto;
  min-width: 0;
  min-height: 0;
  width: 100px;
  text-align: center;
}

body.woocommerce ul.products .onsale {
  transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  -webkit-transform: rotate(30deg);
}

body.woocommerce ul.products li.product .price {
  color: #333333;
}

body.woocommerce #content div.product p.price {
  color: #333333;
  font-size: 14px;
}

body.woocommerce #content div.product p.price del {
  font-size: 14px;
}

body.woocommerce.single-product #content div.product .label {
  z-index: 5;
  left: 315px;
  top: -5px;
}

body.woocommerce.single-product #content div.product ul.products .label {
  left: -5px;
  top: -5px;
}

body.woocommerce ul.products li.product .label, body.woocommerce #content .product .label, body .actionpage.category .label {
  position: absolute;
  background: RGBA(224, 16, 32, 1);
  top: -0.5em;
  border-radius: 10px;
  padding: 10px;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3), inset 0 -1px 0 rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3), inset 0 -1px 0 rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3), inset 0 -1px 0 rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.2);
  left: -0.5em;
}

body .actionpage.category .label {
  left: 0.5em;
}

body header {
  position: relative;
}



body header.site-header {
  background: white;
  min-height: 56px;
}

body header .entry-meta {
  float: right;
  line-height: 3em;
}

body .navbar {
  width: 100%;
  z-index: 1002;
  left: 0;
  right: 0;
  background: #e01020;
  border: none;
  border-radius: 0;
  border-bottom: 5px solid #c20b1a;
  min-height: 45px;
  -webkit-box-shadow: 3px 3px 10px 0px rgba(50, 50, 50, 0.75);
  -moz-box-shadow: 3px 3px 10px 0px rgba(50, 50, 50, 0.75);
  box-shadow: 3px 3px 10px 0px rgba(50, 50, 50, 0.75);
}

body .navbar a {
  color: white;
  padding-top: 12px;
  padding-bottom: 12px;
}

body .navbar a {
  font-size: 16px;
}

body .navbar .dropdown-menu > li > a {
  padding: 5px 15px;
  color: white !important;
  filter: none;
}

body .navbar .dropdown-menu > li > a:hover, body .navbar .dropdown-menu > li > a:focus {
  background-color: #c20b1a !important;
  color: white;
  filter: none;
}

body .navbar .dropdown-menu > li > a:hover, body .navbar .dropdown-menu > li > a:focus {
  filter: none;
}

body .navbar .dropdown-submenu {
  position: relative;
  position: relative;
}

body .navbar .dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
}

body .navbar .dropdown-submenu .dropdown-menu a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: white;
  margin-top: 5px;
  margin-right: -10px;
}

body .navbar .dropdown-submenu.pull-left {
  float: none;
}

body .navbar .dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

body .navbar-inverse #menu-primary-menu {
  padding: 0 15px;
}

@media (min-width: 768px) {
  body .navbar-inverse #menu-primary-menu {
    padding: 0;
  }
}

body .navbar-inverse #menu-primary-menu .dropdown-menu {
  background-color: #C20B1A;
  padding-top: 0;
}

@media (min-width: 768px) {
  body .navbar-inverse #menu-primary-menu .dropdown-menu {
    background-color: #E01020;
    padding-top: inherit;
  }
}

body .navbar-inverse #menu-primary-menu .dropdown-menu > li > a {
  padding: 10px 15px;
  border-top: 1px solid #AF0000;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
}

@media (min-width: 768px) {
  body .navbar-inverse #menu-primary-menu .dropdown-menu > li > a {
    border-top: none;
    box-shadow: none;
  }
}

body .navbar-inverse .navbar-nav > li > a {
  color: white;
}

body .navbar-inverse .navbar-nav > li > a:hover, body .navbar-inverse .navbar-nav > li > a:focus {
  background-color: #c20b1a;
}

body .navbar-inverse .navbar-toggle {
  border: none;
}

body .navbar-inverse .navbar-toggle:hover, body .navbar-inverse .navbar-toggle:focus {
  background-color: #c20b1a;
  text-decoration: none;
}

body .navbar-inverse .navbar-toggle a:hover, body .navbar-inverse .navbar-toggle a:focus {
  background-color: #c20b1a;
  text-decoration: none;
}

body .navbar-inverse .navbar-toggle a:hover.menu-responsive-toggle, body .navbar-inverse .navbar-toggle a:focus.menu-responsive-toggle {
  background-color: transparent;
}

body .navbar-inverse .navbar-nav > .active > a {
  background-color: #c20b1a;
  background-image: none;
  filter: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .navbar-inverse .navbar-nav .open > a {
  background-color: #c20b1a;
  background-image: none;
  filter: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .navbar-inverse .navbar-nav .open > a:hover, body .navbar-inverse .navbar-nav .open > a:focus {
  background-color: #c20b1a;
}

body .navbar-inverse .navbar-nav > .active > a:hover, body .navbar-inverse .navbar-nav > .active > a:focus {
  background-color: #c20b1a;
}

body .navbar-inverse .navbar-collapse, body .navbar-inverse .navbar-form {
  border-color: #c20b1a;
}

body .container-first {
  padding-top: 25px;
}

body .row {
  overflow: hidden;
}

body .gallery-item img {
  width: 95%;
  height: auto;
}

body .tablepress tfoot th, body .tablepress thead th {
  background-color: transparent;
}

body .stock-bg {
  background: #fff url("@{base-path}/zoek-je-motor.jpg") no-repeat;
  width: 100%;
  height: 430px;
  background-size: cover;
  transform: translateZ(0px);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: relative;
}

body .stock-bg .stock-content {
  position: absolute;
  top: 35%;
  left: 0px;
  text-align: center;
  width: 100%;
}

body .stock-bg .stock-content .yellow-bg {
  display: inline-block;
  clear: both;
  font-family: "Abel", sans-serif;
  font-size: 40px;
  padding: 15px;
}

body .row {
  padding-top: 2%;
  padding-bottom: 2%;
  background: white;
}

body .row.news .item {
  margin-bottom: 40px;
}

@media (min-width: 992px) {
  body .row.news .item {
    margin-bottom: 0;
  }
}

body .row.intro {
  padding: 0;
}

body .row.intro h2, body .row.intro .h2 {
  margin-top: 0;
}

body .row.intro img {
  max-width: 100%;
  height: auto;
}

body .row.news article header h3 {
  margin: 15px 0;
  line-height: 0.8;
}

body .row.news figure img {
  max-width: 100%;
  height: auto;
}

body .row.news h3 a {
  font-size: 28px;
  color: #393939;
}

body .row.banners object {
  width: 100%;
  min-height: 50px;
}

body .row.banners img, body .row.banners object {
  max-width: 100%;
}

body .wpx_bannerize_banner_box {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

body .grey {
  background: #ededed;
}

body .news {
  background: #ededed;
  padding: 50px 0;
  @media screen and (min-width: @grid-float-breakpoint){
    padding-bottom: 0;
  }
}

body .news .display-posts-listing img {
  width: 100%;
  height: auto;
}

body .news .display-posts-listing .inner {
  padding: 0 2%;
}

body .news h2 {
  margin: 1.5% 0 0;
}

body .news h2 a {
  color: #393939;
}

body .news h2 a:hover {
  color: #131313;
}

body .news .meta {
  margin: 0 0 2%;
  display: block;
  position: relative;
  left: -1%;
}

body .news .meta > span {
  padding: 0 1% 0 0;
  border-right: 1px solid darkgray;
  margin: 0 0 0 1%;
}

body .news .meta span {
  color: #393939;
  font-size: 12px;
}

body .news .meta span:last-child {
  border-right: none;
}

body .news .meta span .glyphicon {
  margin: 0 1% 0 0;
}

body .news .button-set {
  margin: 2% 0;
}

body.archive article.post, body.author article.post {
  margin: 2em 0 0;
  padding: 2em 0 0;
  border-top: 1px solid #eeeeee;
}

body .entry-content .thumbnail, body .entry-content .wp-post-image {
  height: auto;
  max-width: 100%;
}

body .fcbk_like {
  margin: 3em 0;
}

body .page .entry-meta .date {
  display: none;
}

body footer {
  color: white;
  background: #e01020;
  text-align: center;
}

body footer .text-left {
  text-align: center;
}

@media (min-width: 768px) {
  body footer {
    text-align: left;
  }

  body footer .text-left {
    text-align: left;
  }
}

body footer .row {
  margin: 0.5% 0 0.5% 0;
  background: transparent;
}

body footer section {
  padding: 3% 0;
}

body footer .textwidget {
  font-size: 0.85em;
}

body footer .textwidget table {
  margin: 0 auto 20px;
  text-align: left;
}

@media (min-width: 768px) {
  body footer .textwidget table {
    margin: 0 0 5%;
  }
}

body footer .bottom {
  background: #c20b1a;
  color: white;
  line-height: 4.5em;
  color: white;
}

body footer a, body footer h4, body footer p {
  color: white;
}

body footer a:hover, body footer a:focus {
  color: #fcb7b7;
  text-decoration: none;
}

body footer h4 {
  font-size: 1.15em;
}

body iframe {
  border: 0;
  width: 100%;
}

body.page-template-fullpage-newsletter .logo img {
  background-color: white;
  padding: 4px;
}

body.page-template-fullpage-newsletter .scroll-triggered-box {
  display: none;
  visibility: hidden;
}

body.page-template-fullpage-newsletter .widgets_on_page ul {
  display: block;
  list-style: none;
  padding: 0;
}

body.page-template-fullpage-newsletter .widgets_on_page .widgettitle {
  display: none;
}

body.page-template-fullpage-newsletter #content {
  padding: 4em 15px;
}

body.page-template-fullpage-newsletter #main .row {
  padding: 0 0 1em 0;
  background-color: transparent;
}

body.page-template-fullpage-newsletter #main .columns {
  float: left;
  width: 100%;
  margin: 0 0 1em 0;
}

body.page-template-fullpage-newsletter #main .columns .col-left, body.page-template-fullpage-newsletter #main .columns .col-right {
  float: left;
  width: 50%;
}

body.page-template-fullpage-newsletter #main .columns .col-left {
  padding: 0 7.5px 0 0;
}

body.page-template-fullpage-newsletter #main .columns .col-right {
  padding: 0 0 0 7.5px;
}

body.page-template-fullpage-newsletter #main h2 {
  font-size: 3em;
  margin: 0 0 0.6em;
}

body.page-template-fullpage-newsletter #main .widget_mwznb h4 {
  display: none;
}

body.page-template-fullpage-newsletter #main .widget_mwznb .message {
  background: rgba(255, 255, 255, 0.8);
  color: #666;
  text-shadow: none;
  border-radius: 5px 5px 5px 5px;
  line-height: 2.5em;
  margin: 0 0 1em 0;
}

@media (min-width: 768px) {
  body.page-template-fullpage-newsletter #main > header {
    padding: 5em 0;
  }
}

body.page-template-fullpage-newsletter #main > header .excerpt {
  color: #fff;
  width: 100%;
  text-shadow: 0px 0px 5px black;
}

body.page-template-fullpage-newsletter #main > header .excerpt h1, body.page-template-fullpage-newsletter #main > header .excerpt p {
  color: #fff;
}

body.page-template-fullpage-newsletter #main > header .excerpt h1 {
  font-size: 2.5em;
}

body.page-template-fullpage-newsletter #main > header .excerpt h3 {
  color: #CFCFCF;
  font-size: 1.6em;
  margin: 0 0 1em;
  color: rgba(255, 255, 255, 0.85);
}

body.page-template-fullpage-newsletter #main > header figure img {
  max-width: 100%;
}

body.page-template-fullpage-newsletter #main > header .box {
  max-width: 600px;
  margin: 0 auto;
  padding: 1em;
}

body.page-template-fullpage-newsletter #main .widget_mwznb {
  margin: 2em 0 0;
}

body.page-template-fullpage-newsletter #main .widget_mwznb input[type="email"], body.page-template-fullpage-newsletter #main .widget_mwznb input[type="text"] {
  font-size: 1.5em;
  height: 60px;
  line-height: 60px;
}

body.page-template-fullpage-newsletter #main .widget_mwznb button[type="submit"] {
  width: 100%;
  padding: 0 1em;
  font-size: 1.5em;
  height: 60px;
  line-height: 60px;
  margin: 1em 0 0;
}

body.page-template-fullpage-newsletter #main .widget_mwznb button[type="submit"] span {
  display: inline-block;
}

body.page-template-fullpage-newsletter #main .widget_mwznb button[type="submit"] .loading {
  display: none;
}

body.page-template-fullpage-newsletter #main .widget_mwznb ul {
  padding: 0;
}

body.page-template-fullpage-newsletter #main .widget_mwznb .mwznb-widget.loading button[type="submit"] .text {
  display: none;
}

body.page-template-fullpage-newsletter #main .widget_mwznb .mwznb-widget.loading button[type="submit"] .loading {
  display: inline-block;
}

body.page-template-fullpage-newsletter #main .pointers li {
  font-size: 1.3em;
  margin: 0 0 1em;
}

body.page-template-fullpage-newsletter #main .pointers li b {
  text-transform: uppercase;
}

body.page-template-fullpage-newsletter #main .pointers li p {
  margin: 0 0 0 14px;
  color: #777;
}

body.page-template-fullpage-newsletter #main .pointers .fa {
  width: 10px;
  color: #E01020;
}

body.page-template-landingspage-newsletter #content {
  padding: 4em 15px;
}

body.page-template-landingspage-newsletter #main .row {
  padding-top: 0;
}

body.page-template-landingspage-newsletter #main h2 {
  font-size: 3em;
  margin: 0 0 0.6em;
}

body.page-template-landingspage-newsletter #main .widget_mwznb h4 {
  display: none;
}

body.page-template-landingspage-newsletter #main .widget_mwznb .message {
  background: rgba(255, 255, 255, 0.8);
  color: #666;
  text-shadow: none;
  border-radius: 5px 5px 0px 0px;
  line-height: 2.5em;
}

body.page-template-landingspage-newsletter #main > header {
  background: #2b3130;
  background-image: url("../images/bg-landingspage-newsletter.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  margin: 0;
  overflow: hidden;
  min-height: 23em;
}

@media (min-width: 768px) {
  body.page-template-landingspage-newsletter #main > header {
    padding: 7em 0;
  }
}

body.page-template-landingspage-newsletter #main > header .excerpt {
  color: #fff;
  width: 100%;
  text-shadow: 0px 0px 5px black;
}

body.page-template-landingspage-newsletter #main > header .excerpt h1, body.page-template-landingspage-newsletter #main > header .excerpt p {
  color: #fff;
}

body.page-template-landingspage-newsletter #main > header .excerpt h1 {
  font-size: 3em;
}

body.page-template-landingspage-newsletter #main > header .excerpt h3 {
  color: #CFCFCF;
  font-size: 1.6em;
  margin: 0 0 1em;
  color: rgba(255, 255, 255, 0.85);
}

body.page-template-landingspage-newsletter #main > header figure img {
  max-width: 100%;
}

body.page-template-landingspage-newsletter #main > header .box {
  max-width: 600px;
  margin: 0 auto;
  padding: 1em;
}

body.page-template-landingspage-newsletter #main #newsletter {
  margin: 2em 0 0;
}

body.page-template-landingspage-newsletter #main #newsletter input[type="email"] {
  font-size: 1.5em;
  height: 60px;
  line-height: 60px;
}

body.page-template-landingspage-newsletter #main #newsletter input[type="submit"] {
  width: 100%;
  padding: 0 1em;
  font-size: 1.5em;
  height: 60px;
  line-height: 60px;
  margin: 1em 0 0;
}

body.page-template-landingspage-newsletter #main #newsletter ul {
  padding: 0;
}

body.page-template-landingspage-newsletter #main .pointers li {
  font-size: 1.3em;
  margin: 0 0 1em;
}

body.page-template-landingspage-newsletter #main .pointers li b {
  text-transform: uppercase;
}

body.page-template-landingspage-newsletter #main .pointers li p {
  margin: 0 0 0 14px;
  color: #777;
}

body.page-template-landingspage-newsletter #main .pointers .fa {
  width: 10px;
  color: #E01020;
}

body.page-template-landingspage-newsletter #main .display-posts-listing {
  padding: 0;
  list-style: none;
}

body.page-template-landingspage-newsletter #main .display-posts-listing header .meta {
  position: absolute;
  top: 0;
  color: #777;
}

body.page-template-landingspage-newsletter #main .display-posts-listing header .meta .glyphicon {
  margin: 0 0.5em 0 0;
}

body.page-template-landingspage-newsletter #main .display-posts-listing header h2 {
  text-indent: 100px;
  font-size: 1.3em;
  margin: 0 0 1em;
}

body.page-template-page-actions .flex-col {
  margin-bottom: 30px;
}

body.page-template-page-actions .flex-col > a {
  background: #EDEDED;
  display: block;
  width: 100%;
  padding: 15px;
  color: #393939;
  text-decoration: none;
  transition: background 0.4s ease-in-out;
}

body.page-template-page-actions .flex-col > a:hover {
  background: #d4d4d4;
  text-decoration: none;
}

body.page-template-page-actions .flex-col h2 {
  text-decoration: none;
  margin: 0 0 10px;
}

body.page-template-page-actions .flex-col figure {
  background-color: #fff;
}

body.page-template-page-actions .flex-col figure > img {
  height: auto;
  max-width: 100%;
}

body.tax-acties header {
  margin-bottom: 15px;
}

body.tax-acties header h1 {
  margin: 0;
}

@media (min-width: 768px) {
  body.tax-acties header h1 {
    float: left;
  }

  body.tax-acties header .navigation {
    float: right;
  }
}

body.tax-acties .action {
  margin-bottom: 30px;
}

body.tax-acties .action article {
  background: #EDEDED;
  display: block;
  width: 100%;
  padding: 15px;
  color: #393939;
  text-decoration: none;
  transition: background 0.4s ease-in-out;
  position: relative;
  padding-bottom: 66px;
}

body.tax-acties .action figure {
  background-color: #fff;
}

@media (min-width: 768px) {
  body.tax-acties .action figure {
    overflow: hidden;
    height: 250px;
  }
}

@media (min-width: 992px) {
  body.tax-acties .action figure {
    height: 150px;
  }
}

@media (min-width: 1200px) {
  body.tax-acties .action figure {
    height: 180px;
  }
}

body.tax-acties .action figure img {
  height: auto;
  max-width: 100%;
}

body.tax-acties .action .excerpt {
  border-bottom: 1px solid #393939;
  margin-bottom: 10px;
  padding-bottom: 5px;
}

body.tax-acties .action .permalink {
  position: absolute;
  bottom: 15px;
  right: 15px;
}

@media (min-width: 768px) {
  .flex-row {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .flex-col {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

/* Small devices (tablets, $screen-sm-min and up) */
@media (min-width: 768px) {
  body #content .row {
    overflow: visible;
  }

  body h3 {
    font-size: 28px;
  }

  body h3 a, body h3:hover {
    font-size: 28px;
  }



  body .navbar {
    position: relative;
    height: 49px;
  }

  body .navbar .dropdown-menu {
    background: #e01020 url("@{base-path}/menu-bg.png") repeat-x;
    margin: 5px 0 0;
    border: none;
  }

  body #occassions .item {
    width: 33.33333%;
  }

  body .navbar .navbar-header {
    float: none;
    display: inline-block;
  }

  body .navbar .collapse {
    display: inline-block !important;
    float: right;
  }

  body .navbar .collapse ul.nav {
    float: none;
    display: inline-block;
  }
}

@media (min-width: 1200px) {
  body .wpx_bannerize_banner_box {
    width: 50%;
    float: left;
  }

  body .links {
    display: block;
  }
}
