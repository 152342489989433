.calendar {
	h1,h2,h3 {
		margin: 0 0 10px;
		font-size: @font-size-h4;
	}
	.content{
		margin-bottom: 60px;
	}
	ul.items {
		> li {
			border: 1px solid rgba(0, 0, 0, 0.1);
			padding: 15px;
			margin-bottom: 20px;
			line-height: 1;
			background: rgba(255,255,255,.6);
			border-left: 2px solid @brand-primary;
			transition: all .2s ease-in-out;
			&:hover{
				background: rgba(255, 255, 255, 0.9);
				border-left-width: 5px;
				border-color: @brand-primary;
			}
		}
		h4 {
			margin-top: 0;
		}
		margin-bottom: 15px;
	}
	.date-start-end{
		> li{
			width: 100%;
			@media(min-width: @screen-md-min){
				width: auto;
			}
		}
		.fa-angle-right{
			position: relative;
			top: -15px;
			display: none;
			@media (min-width: @screen-md-min){
				display: inline-block;
			}
		}
		.fa-angle-down{
			display: inline-block;
			@media (min-width: @screen-md-min){
				display: none;
			}
		}
	}
	.row {
		background-color: transparent;
		padding: 0;
	}
	.date {
		.day {
			color: @gray;
			font-size: 2em;
		}
		.month {
			font-size: 1em;
			color: @gray-light;
		}
	}
	.time{
		color: @gray-light;
		font-size: .8em;
	}
}
.sidebar-agenda{
	h3{
		margin-top: 0;
	}
}