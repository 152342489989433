#occassions .infoBlock {
	background: none;
	border: none;
	box-shadow: none;
	padding: 0;
}

#occassions h3 {
	margin-top: 0;
}

#occassions ul.thumbnails {
	list-style: none;
	padding: 0;
}

#occassions ul.thumbnails img {
	max-width: 100%;
	width: 100%;
	height: auto;
}

#occassions ul.thumbnails .unitBlock {
	position: relative;
	overflow: hidden;
}

#occassions ul.thumbnails .unitBlock .caption {
	padding-bottom: 40px;
}

#occassions ul.thumbnails .unitBlock .caption p {
	font-size: 14px;
}

#occassions ul.thumbnails .unitBlock .caption h5,
#occassions ul.thumbnails .unitBlock .caption h6 {
	display: inline-block;
	font-size: 16px;
	font-weight: bold;
}

#occassions ul.thumbnails .unitBlock .caption h6:before {
	content: " - ";
}

#occassions ul.thumbnails .unitBlock .caption .clearfix {
	display: none;
	visibility: hidden;
}

#occassions ul.thumbnails .unitBlock .memo {
	display: none;
}

#occassions ul.thumbnails .unitBlock .unitPrice {
	float: right;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #e30613;
	color: #fff;
	padding: 5px 15px;
	border-radius: 0px 0px 5px 5px;
	font-weight: bold;
	text-align: right;
}

#occassions ul.thumbnails .unitBlock {
	.nuWrap {
		display: none;
	}
	a {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
}

#occassions ul.thumbnails li.item {
	float: left;
	padding-right: 15px;
	padding-left: 15px;
}

#occassions ul.thumbnails li.item a.ribbon {
	bottom: auto;
	transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	text-align: center;
	right: auto;
	left: -30px;
	width: 115px;
	top: 15px;
	line-height: 24px;
	text-transform: lowercase;
	background-color: #e30613 !important;
}

#occassions .filterBlok br {
	display: none;
	visibility: hidden;
}

#occassions .filterBlok > span {
	margin: 0 5px 10px 0;
	display: inline-block;
	float: none;
}

#occassions .filterBlok ul {
	display: inline-block;
	list-style: none;
	margin: 0 0 1em;
	padding: 0;
}

#occassions .filterBlok ul li {
	display: inline-block;
}

#occassions .filterBlok ul a {
	border: 1px solid #d9edf7;
	border-radius: 5px;
	background: rgba(217, 237, 247, 0.15);
	padding: 2px 10px;
	line-height: 1;
	color: #4c6875;
}

#occassions .filterBlok ul a:hover {
	text-decoration: none;
	background: rgba(217, 237, 247, 0.55);
}

#occassions .filterBlok ul a .glyphicon {
	display: inline;
	top: 3px;
	color: #e30613;
}

#occassions .pagination {
	float: right;
	margin: 15px 0;
}

@media (min-width: 768px) {
	#occassions .pagination {
		margin-top: 0;
	}
}

#occassions .pagination li {
	display: inline-block;
	border-right: 1px solid #e0dadf;
}

#occassions .pagination li a {
	padding: 15px 15px;
	display: block;
	font-size: 14px;
}

#occassions .pagination li a:hover,
#occassions .pagination li a:focus {
	background: #f7f6f7;
}

#occassions .pagination li.active a {
	background: #f7f6f7;
}

#occassions .control-title-wrap {
	background: #eee;
	padding: 0.3em 0.5em;
	margin: 0.5em 0 0;
}

#occassions .control-title-wrap .control-toggle-link {
	float: right;
}

#occassions .controls .slider-input {
	width: 50%;
	float: left;
}

#occassions .controls .slider-input input {
	width: 90%;
	padding: 6px 12px;
	font-size: 14px;
	line-height: 1.42857;
	color: #555;
	vertical-align: middle;
	border: 1px solid #ccc;
}

#occassions .media img {
	max-width: 100%;
	height: auto;
	padding: 0.25em;
}

#occassions .specs h1 {
	margin: 0;
}

#occassions .specs #backButton {
	display: none;
}

@media (min-width: 768px) {
	#occassions .specs #backButton {
		display: inline-block;
		float: left;
	}
}

#occassions .specs .prevnext #nextButton {
	float: right;
}

@media (min-width: 768px) {
	#occassions .specs .prevnext {
		float: right;
	}

	#occassions .specs .prevnext #prevButton {
		margin-right: 0.5em;
	}

	#occassions .specs .prevnext #nextButton {
		margin-left: 0.5em;
	}
}

#occassions .specs .prevnext div a {
	display: none;
}

#occassions .specs .priceWrap span {
	font-size: 24px;
}

#occassions .ui-slider {
	z-index: 0;
}

@media screen and (min-width: 320px) and (orientation: landscape) {
	.recent-occasions .occasion {
		width: 50%;
		float: left;
	}
}

@media screen and (min-width: 992px) {
	.recent-occasions .occasion {
		width: 25%;
	}
}

.recent-occasions a {
	display: block;
	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
	margin-bottom: 20px;
	line-height: 1.42857;
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 4px;
}

.recent-occasions img {
	width: 100%;
	height: auto;
}

.recent-occasions figcaption,
.recent-occasions p {
	padding: 0 10px;
}

.recent-occasions a,
.recent-occasions a:hover {
	text-decoration: none;
	color: #333;
}

.recent-occasions figcaption h4 {
	font-size: 17px;
	font-weight: bold;
}

.recent-occasions .price {
	background-color: #e30613;
	color: #fff;
	padding: 5px 15px;
	border-radius: 0px 0px 5px 5px;
	font-weight: bold;
	text-align: right;
	display: block;
}

.occasion-filter .container {
	padding-top: 15px;
	padding-bottom: 15px;
}

.occasion-filter .container.container-heading {
	background: transparent;
	padding-bottom: 0;
	padding-top: 0;
}

.occasion-filter .container.container-heading h3 .fa-search {
	display: none;
}

@media (min-width: 768px) {
	.occasion-filter .container.container-heading h3 {
		margin: 0;
		background: #333;
		display: inline-block;
		margin-left: -15px;
		padding: 15px 22px;
		color: #fff;
	}

	.occasion-filter .container.container-heading h3 .fa-search {
		display: inline-block;
		margin-right: 10px;
		font-size: 22px;
		line-height: 28px;
	}
}

.occasion-filter .row {
	padding-top: 0;
	padding-bottom: 0;
}

.occasion-filter h3 {
	margin: 0 0 15px;
}

.occasion-filter .btn-group-submit .btn {
	display: block;
	width: 100%;
}

.occasion-filter .btn-group-submit .btn .fa-filter {
	margin: 0 10px 0 0;
}

@media (min-width: 768px) {
	#occassions .specs > h1 {
		display: block;
		float: left;
		margin: 15px 0px 0px;
		font-weight: bold;
	}

	#occassions .specs > h1 span {
		font-weight: normal;
	}

	#occassions .specs .label {
		background-color: #1f8e23;
		float: left;
		padding: 2px 8px;
		line-height: normal;
		cursor: default;
		font-weight: normal;
		margin: 25px 0px 0px 15px;
		font-size: 12px;
	}

	#occassions .specs > .row .col-md-7 {
		width: 100%;
	}

	#occassions .specs .prevnext {
		width: auto;
	}
}
